import React, { Component } from "react"
//import { Link } from "gatsby"
import Layout from "../components/layout/layout"
//import Image from "../components/image"
import SEO from "../components/layout/seo"
import PropTypes from 'prop-types';
import Portfolio from "../components/portfolio";
import { Link } from "gatsby";

class GalleryTopicPageTemplate extends Component {


	componentDidMount() {

	}

	render() {

        var content = this.props.data.contentfulThemeDeGalerie;
        var galleries = [];
       
        this.props.data.allContentfulGalerie.edges.forEach(edge=>{galleries.push(edge.node)});

		return(
		<Layout>
    <SEO title={content.metaTitle || content.title} description={content.metaDescription} keywords={content.metaKeywords} />
    <section className="module" id="profile">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="m-title c-align">
            <h1>{content.title}</h1>
            <h6><Link to="portfolio">&lt; Toutes les galeries</Link></h6>
          </div>
        </div>
      </div>
    </div>
      <Portfolio galleries={galleries}/>
  </section>

	  </Layout>)
	}
}




GalleryTopicPageTemplate.propTypes = {
	data: PropTypes.object.isRequired,	
}

export default GalleryTopicPageTemplate


export const pageQuery = graphql`
  query ($id: String!) {
      contentfulThemeDeGalerie(id: {eq: $id}) {
          title
          metaKeywords
          metaDescription
      }

      allContentfulGalerie(filter: {topic: {id: { eq: $id }}}, sort: {fields: [sortValue,title], order: ASC}) {
        edges {
          node {
            id
            url
            title
            imageSquare {
                fluid(resizingBehavior: FILL, maxWidth: 920, maxHeight: 920) {
                  src
                  srcSet
                  sizes
                }
            }
            topic {
                id
                title
                url
            }

          }
        }
      }      
  }
`